<template>
    <modal ref="modalCrearBodega" titulo="Crear Bodega" tamano="modal-lg" adicional="Crear" @adicional="crearBodega">
        <ValidationObserver ref="validator">
            <div class="px-5 mx-5">
                <div>
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:150">
                        <p class="input-label-top">Nombre bodega</p>
                        <el-input v-model="create.nombre" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                    </div>
                <titulo-divisor titulo="Ubicación" class="text-general" />
                <div class="row">
                    <div class="col-6">
                        <ValidationProvider v-slot="{ errors }" name="pais" rules="required|max:150">
                            <p class="input-label-top">País</p>
                            <el-input v-model="create.pais" @keyup.native="setDireccion" size="small" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <ValidationProvider v-slot="{ errors }" name="estado" rules="required|max:150">
                            <p class="input-label-top">Sector / Departamento / Estado</p>
                            <el-input v-model="create.estado" @keyup.native="setDireccion" size="small" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <ValidationProvider v-slot="{ errors }" name="ciudad" rules="required|max:150">
                            <p class="input-label-top">Ciudad</p>
                            <el-input v-model="create.ciudad" @keyup.native="setDireccion" size="small" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <ValidationProvider v-slot="{ errors }" name="dirección" rules="required|max:150">
                            <p class="input-label-top">Dirección</p>
                            <el-input v-model="create.direccion" @keyup.enter.native="geoLocalizacion" @keyup.native="setDireccion" size="small" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                </div>
                <titulo-divisor titulo="Ubicación por mapa" class="text-general" />
                <div class="mt-1">
                    <mapa-leaflet ref="el_mapa" v-if="renderMapa" class="mapa" @localizacion="getCoordenadas"/>
                    <p v-if="!create.latitud || !create.longitud" class="f-11 text-danger">El campo Ubicación dentro del mapa es obligatorio</p>
                </div>
                <titulo-divisor titulo="Imagenes Bodega" class="text-general mt-3" />
                <div class="mt-3 d-flex">
                    <upload-files ref="compFiles" @files="filesToUpload" :message="`Seleccione máximo ${limitImag} imágenes`" :filesType="filesType" :limitFile="limitImag" />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions } from 'vuex'
import GetCordenadasUseCase from '~/domain/use_cases/mapas/getCordenadasUseCase'
import uploadFiles from '../components/uploadFiles.vue'
import Aws from '~/services/aws'
export default {
    components: { uploadFiles },
    name: 'CrearBodega',
    data() {
        return {
            create: {
                'nombre': '',
                'estado_bodega': 1,
                'pais': '',
                'estado': '',
                'ciudad': '',
                'direccion': '',
                'longitud': '',
                'latitud': '',
                'bodegas_imagenes': []
            },
            renderMapa: false,
            direccion: '',
            files: [],
            limitImag: 5,
            filesType: 'image/png, image/jpeg, image/jpg',
        }
    },
    methods: {
        ...mapActions({
            Action_create_bodega: 'bodega/bodega/Action_create_bodega',
            Action_get_bodegas: 'bodega/bodega/Action_get_bodegas',
        }),
        toggle(){
            this.clear()
            this.renderMapa = false
            this.$refs.modalCrearBodega.toggle()
            setTimeout(() => this.renderMapa = true , 800)
        },
        getCoordenadas(coordenadas){
            this.create.latitud = coordenadas.latitud
            this.create.longitud = coordenadas.longitud
        },
        filesToUpload(files){
            this.files = files
        },
        async crearBodega(){
            const valid = await this.$refs.validator.validate()
            if(!this.create.latitud || !this.create.longitud) return
            if (valid) {
                this.$try(async () => {
                    let imgs = []
                    if (this.files.length) {
                        const promesas = this.files.map(e => Aws.uploadFile({file: e, path: 'bodegas'}))
                        imgs = await this.resolverPromise(promesas)
                    }
                    this.create.bodegas_imagenes = imgs
                    await this.Action_create_bodega(this.create)
                    this.$refs.modalCrearBodega.toggle()
                    await this.Action_get_bodegas()
                });
            }
            this.clear()
        },
        async resolverPromise(promesas){
            const promises = await Promise.allSettled(promesas)
            return promises.map(e => {
                if (e.status === 'fulfilled') return { imagen: e.value}
            })
        },
        setDireccion(){
            this.direccion = this.create.pais + " " + this.create.estado + " " + this.create.ciudad + " " + this.create.direccion
            this.pressed(this.direccion)
        },
        setZoom(){
            if ([this.create.estado, this.create.ciudad, this.create.direccion].every(el => el === '')) return 4
            if ([this.create.ciudad, this.create.direccion].every(el => el === '')) return 6
            if ([this.create.direccion].every(el => el === '')) return 10
            return 15
        },
        pressed(direccion){
            this.direccion = direccion
            let interval = setInterval(() => {
                if(direccion == this.direccion) this.geoLocalizacion()
                clearInterval(interval);
            }, 600);
        },
        async geoLocalizacion(){
            const data = await GetCordenadasUseCase(this.direccion)
            if (data) {
                this.$refs.el_mapa.setLocalizacionProp({latitud: data[0].lat, longitud: data[0].lon, zoom: this.setZoom()})
            }
        },
        clear(){
            this.create = { 'nombre': '', 'estado_bodega': 1, 'bodegas_imagenes': [], 'pais': '', 'estado': '', 'ciudad': '', 'direccion': '', 'longitud': '', 'latitud': '', }
            this.$refs.compFiles.cleanFileList()
            this.$refs.validator.reset()
            this.files = []
        }
    },
}
</script>

<style lang="scss" scoped>
    .mapa{
        height: 250px;
    }
</style>