<template>
    <section class="px-3 ml-3">
        <navbar-admin icon="icon-archive" />
        <titulo-divisor titulo="Bodegas registradas">
            <div class="row d-middle-center">
                <div class="icon-option">
                    <el-tooltip content="Crear Bodega" placement="bottom" effect="light" visible-arrow>
                        <i 
                            class="icon-plus-circle f-30" 
                            :class="permitAction('bodegas.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('bodegas.crear', modalCrearBodega)"
                        />
                    </el-tooltip>
                </div>
                <div class="col auto">
                    <el-input v-model="filter.buscar" placeholder="Buscar bodega" clearable @keypress.enter.native="listarBodegas" size="small" />
                </div>
                <div class="col-auto px-1">
                    <el-tooltip content="Dashboard" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d cr-pointer">
                            <i class="icon-finance text-white" @click="goDashboard()" />
                        </button>
                    </el-tooltip>
                </div>
                <div class="col-auto pl-1">
                    <div v-if="contar == 0" class="col-auto my-auto px-1 icon-option">
                        <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                    <div v-if="contar > 0" class="col-auto filtro-contador my-auto px-1">
                        <div class="d-flex border-general br-4">
                            <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                            <div class="d-middle px-2">
                                <p class="f-12"> {{ contar }} </p>
                                <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="clearAllFilters" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>

        <div class="d-middle-center mt-5">
            <div class="text-inactivo d-middle-center mr-5">
                <p class="f-13 f-500 mr-2">Inactivo</p>
                <i class="icon-circle-fill f-20" />
            </div>
            <div class="text-green d-middle-center">
                <p class="f-13 f-500 mr-2">Activo</p>
                <i class="icon-circle-fill f-20" />
            </div>
        </div>

        <!-- Cabecera -->
        <div class="row mt-4 header-table py-3">
            <div class="col-4 my-auto">
                <div class="row">
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Nombre bodega</p>
                    </div>
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Pais</p>
                    </div>
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Ubicación</p>
                    </div>
                </div>
            </div>
            <div class="col-4 my-auto">
                <div class="row">
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Dirección</p>
                    </div>
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Cantidad materiales</p>
                    </div>
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Valor materiales</p>
                    </div>
                </div>
            </div>
            <div class="col-4 my-auto">
                <div class="row">
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Fecha última salida</p>
                    </div>
                    <div class="col-4 my-auto">
                        <p class="f-14 f-600 text-general text-center">Fecha última entrada</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contenido -->
        <div id="accordion">
            <div v-for="(data, index) in bodegas.data" :key="index">
                <div class="cr-pointer" data-toggle="collapse" :data-target="`#collapseOn${index}`" aria-expanded="false" aria-controls="collapseOn">
                    <div class="row py-2 border">
                        <div class="col-4 my-auto">
                            <div class="row">
                                <div class="col-4 my-auto">
                                    <p class="f-14 f-600 text-muted text-center">{{ (data.nombre && data.nombre != null)  ? data.nombre : '-'  }}</p>
                                </div>
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ (data.pais && data.pais != null)  ? data.pais : '-'  }}</p>
                                </div>
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ (data.ciudad && data.ciudad != null)  ? data.ciudad : '-'  }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 my-auto">
                            <div class="row">
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ (data.direccion && data.direccion != null)  ? data.direccion : '-'  }}</p>
                                </div>
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ (data.cantidad_materiales && data.cantidad_materiales != null)  ? data.cantidad_materiales : '-'  }}</p>
                                </div>
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ formatoMoneda(data.valor_materiales) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 my-auto">
                            <div class="row">
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ data.fecha_ultima_salida | helper-fecha('DD MMM YYYY') }}</p>
                                </div>
                                <div class="col-4 my-auto">
                                    <p class="f-14 text-muted text-center">{{ data.fecha_ultima_entrada | helper-fecha('DD MMM YYYY') }}</p>
                                </div>
                                <div class="col-4 my-auto d-middle justify-content-around">
                                    <i :class="{'text-inactivo' : data.estado_bodega === 0, 'text-green' : data.estado_bodega === 1}" class="icon-circle-fill f-25" />
                                    <p 
                                        class="icon-option">
                                        <i 
                                            class="icon-pencil f-30" 
                                            :class="permitAction('bodegas.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                                            @click.stop="functionPermitAction('bodegas.editar', modalEditarBodega, [data])"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Contenido Acordeon -->
                <div :id="`collapseOn${index}`" class="collapse" :aria-labelledby="`headingOn${index}`" data-parent="#accordion">
                    <div class="my-4">
                        <div class="d-middle-center gap-3">
                            <div v-for="(image, index) in data.bodegas_imagenes" :key="image.id" class="cont-img br-4 position-relative">
                                <img @click="modalGallery(data.bodegas_imagenes, data.nombre, index)" :src="image.imagen | helper-storage " alt="" class="obj-cover h-100 w-100 br-4">
                                <el-popover
                                    placement="top"
                                    width="320"
                                    trigger="click"
                                    :disabled="!permitAction('bodegas.imagenes.eliminar')"
                                    >
                                    <p class="mb-4 text-muted text-center f-13">¿Está seguro que desea eliminar esta imagen?</p>
                                    <div class="bg-f5 py-1 border-top" style="text-align: right; margin: -12px">
                                        <button @click="deleteImage(index, image.id)" class="mr-3 btn btn-general f-13 mx-3">confirmar</button>
                                    </div>
                                    <div slot="reference"
                                        class="rounded-circle wh-30 cr-pointer position-absolute"
                                        style="bottom: 5px; left: 50%; margin-left: -16px; background: #fffc;"
                                    >
                                        <i 
                                            class="icon-trash-can-outline f-20" style="margin-left: 1px;"
                                            :class="permitAction('bodegas.imagenes.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                                            @click="functionPermitAction('bodegas.imagenes.eliminar')"

                                        />
                                    </div>
                                </el-popover>
                            </div>
                            <div v-if="numImagenMaxima(data.bodegas_imagenes)" 
                                @click="functionPermitAction('bodegas.imagenes.agregar', () => {$refs.openModalAddImagen.toggle(data)})" 
                                class="icon-option d-middle-center">
                                <el-tooltip content="Añadir imagen" placement="top" effect="light" visible-arrow>
                                    <i 
                                        class="icon-plus-circle f-35" 
                                        :class="permitAction('bodegas.imagenes.agregar') ? 'cr-pointer' : 'cr-not-allowed'" 
                                    />
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="d-middle-center mt-4">
                            <button class="btn btn-general f-12 px-4" @click="verBodega(data.id)">Mas información</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Paginacion -->
        <div class="d-middle-center my-5">
            <pagination :pagination="bodegas.pagination" @paginate="listarPag"/>
        </div>

        <!-- Modal Filtros -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row justify-content-center mx-0">
                <div class="col-11 mt-4">
                    <p class="input-label-top">Nombre</p>
                    <el-select v-model="filter.nombre" filterable clearable placeholder="Seleccionar nombre" size="small" class="w-100">
                        <el-option v-for="item in select_bodegas.bodegas" :key="item.id" :label="item.nombre" :value="item.nombre" />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_value" range :min="select_bodegas.valor_minimo" :max="select_bodegas.valor_maximo" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Rango fecha ultima salida</p>
                    <el-date-picker
                        v-model="range_salida"
                        type="daterange"
                        range-separator="a"
                        size="small"
                        start-placeholder="Fecha Inicio"
                        value-format="yyyy-MM-dd"
                        class="w-100 mt-1"
                        end-placeholder="Fecha Fin">
                    </el-date-picker>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Rango fecha ultima entrada</p>
                    <el-date-picker
                        v-model="range_entrada"
                        type="daterange"
                        range-separator="a"
                        size="small"
                        start-placeholder="Fecha Inicio"
                        value-format="yyyy-MM-dd"
                        class="w-100 mt-1"
                        end-placeholder="Fecha Fin">
                    </el-date-picker>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Pais</p>
                    <el-select v-model="pais" clearable placeholder="Pais" @change="getState" filterable size="small" class="w-100">
                        <el-option v-for="item in select_countries" :key="item.id" :label="item.pais" :value="[item.pais, item.id]" />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Sector/Departamento/Estado</p>
                    <el-select v-model="filter.estado" clearable filterable placeholder="Sector/Departamento/Estado" size="small" class="w-100">
                        <el-option v-for="item in selects_states" :key="item.id" :label="item.estado" :value="item.estado" />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Tipo material</p>
                    <el-select
                        v-model="filter.tipo_material"
                        multiple
                        size="small"
                        class="w-100"
                        collapse-tags>
                        <el-option
                            v-for="item in select_bodegas.tipos_materiales"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Rango No. materiales</p>
                    <el-slider v-model="range_cantidad" range :min="select_bodegas.materiales_minimo" :max="select_bodegas.materiales_maximo" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearAllFilters"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="listarBodegas"> Filtrar </button>
            </div>
        </modal-lateral>

        <modal-crear-bodega ref="openCrearModalBodega"/>
        <modal-editar-bodega ref="openEditarModalBodega" />
        <modal-add-imagen ref="openModalAddImagen" />
        <image-gallery ref="openModalGallery" />
        
    </section>
</template>

<script>
import modalCrearBodega from './partials/modalCrearBodega.vue'
import ModalEditarBodega from './partials/modalEditarBodega.vue';
import ModalAddImagen from './partials/modalAddImagen.vue';
import ImageGallery from '../../components/modal/imageGallery.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  components: { modalCrearBodega, ModalEditarBodega, ModalAddImagen, ImageGallery },
  name: 'Bodegas',
    computed: {
        ...mapGetters({
            bodegas: 'bodega/bodega/bodegas',
            select_bodegas: 'selects/selects/select_bodegas',
            select_countries: 'selects/selects/select_countries',
            selects_states: 'selects/selects/select_states',
            filters: 'bodega/bodega/filters'
        }),
        filter:{
            get(){ return this.filters },
            set(val){ this.setFilters(val) }
        },
        range_value:{
            get(){ return [this.filter.valor_inicial, this.filter.valor_final] },
            set(val){ this.filter.valor_inicial = val[0], this.filter.valor_final = val[1] },
        },
        range_cantidad:{
            get(){ return [this.filter.cantidad_inicial, this.filter.cantidad_final] },
            set(val){ this.filter.cantidad_inicial = val[0], this.filter.cantidad_final = val[1] },
        },
        range_salida:{
            get(){ 
                return this.filter.fecha_inicial_ultima_salida !== null
                    ? [this.filter.fecha_inicial_ultima_salida, this.filter.fecha_final_ultima_salida]
                    : null
            },
            set(val){
                this.filter.fecha_inicial_ultima_salida = val !== null ? val[0] : null,
                this.filter.fecha_final_ultima_salida = val !== null ? val[1] : null
            },
        },
        range_entrada:{
            get(){ 
                return this.filter.fecha_inicial_ultima_entrada !== null
                    ? [this.filter.fecha_inicial_ultima_entrada, this.filter.fecha_final_ultima_entrada]
                    : null
            },
            set(val){
                this.filter.fecha_inicial_ultima_entrada = val !== null ? val[0] : null,
                this.filter.fecha_final_ultima_entrada = val !== null ? val[1] : null
            },
        },
        pais:{
            get(){ return this.filter.pais },
            set(val){ this.filter.pais = val ? val[0] : '' }
        },
        contar(){
            return this.filter.count();
        }
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'bodega.main'
        ]);
        // await this.Action_get_bodegas()
        await this.Action_get_selects_bodegas()
        await this.Action_get_select_countries()
        this.clearAllFilters()
    },
    methods: {
        ...mapActions({
            Action_get_bodegas: 'bodega/bodega/Action_get_bodegas',
            Action_update_bodega: 'bodega/bodega/Action_update_bodega',
            Action_clear_filters: 'bodega/bodega/Action_clear_filters',
            Action_get_selects_bodegas: 'selects/selects/Action_get_selects_bodegas',
            Action_get_select_countries: 'selects/selects/Action_get_select_countries',
            Action_get_selects_states: 'selects/selects/Action_get_select_states'
        }),
        ...mapMutations({
            setFilters: 'bodega/bodega/setFilters'
        }),

        async listarBodegas(){
            await this.Action_get_bodegas()
        },
        numImagenMaxima(imagenes){
            return (Array.isArray(imagenes) && imagenes.length < 5)
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        modalCrearBodega(){
            this.$refs.openCrearModalBodega.toggle()
        },
        modalEditarBodega(bodega){
            this.$refs.openEditarModalBodega.toggle(bodega)
        },
        modalGallery(imagenes, title, index){
            this.$refs.openModalGallery.toggle(imagenes.map(el => el.imagen), title, index)
        },
        async listarPag(pag){
            await this.Action_get_bodegas({page: pag})
        },
        verBodega(id){
            this.$router.push({ name: 'bodega.materiales', params:{ id_bodega : id } })
        },
        deleteImage(index, id){
            this.$try(async ()=>{
                const id_bodega = this.bodegas.data[index].id
                const bodega = this.bodegas.data[index]
                bodega.bodegas_imagenes = bodega.bodegas_imagenes.filter(e => e.id != id)
                await this.Action_update_bodega({id: id_bodega, payload: bodega})
                await this.Action_get_bodegas()
            })
        },
        goDashboard(){
            this.$router.push({ name: 'bodega.dashboard'})
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async getState(pais){
            await this.Action_get_selects_states({id_pais: pais[1]})
        },
        async clearAllFilters(){
            this.Action_clear_filters(this.select_bodegas)
            await this.Action_get_bodegas()
        },
    },
}
</script>

<style lang="scss" scoped>
.header-table{
    border: 1px solid #DDDDDD;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid #0B56A7;
}
img{
    max-height: 109px;
    max-width: 187px;
}
.text-inactivo{
    color: #E14A1B;
}
</style>