<template>
    <div class="container">
        <div v-if="filesToUpload.length < limitFile" class="d-middle">
            <input ref="uploaderInput" class="d-none" type="file" :accept="filesType" name="fileUploader" @change="setFiles" multiple />
            <input class="btn btn-general text-white f-13 px-3" type="button" name="fileUploaderTrigger" value="Añadir" @click="$refs.uploaderInput.click()" />
            <p v-if="message" class="text-muted f-12 ml-4"> {{message}} </p>
        </div>
        <div class="d-middle flex-wrap gap-1 mt-3">
            <div v-for="(file, index) in filesToUpload" :key="index" class="d-middle border-general br-3">
                <p class="text-muted f-12 mx-2">{{ file.name }}</p>
                <span @click="deleteFile(index)" class="bg-general f-15 text-white br-2"><i class="icon-cancel cr-pointer px-1"/></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        limitFile: {
            type: Number,
            default: 9999
        },
        filesType: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            filesToUpload: [],
        }
    },
    watch:{
        filesToUpload:{
            handler(val){
                this.$emit('files', val)
            },
            deep: true
        }
    },
    methods:{
        setFiles(e){
            this.$try(()=>{
                if (this.filesToUpload.length < this.limitFile && e.target.files.length <= (this.limitFile - this.filesToUpload.length)) {
                    let fileArray = Array.from(e.target.files)
                    fileArray.forEach(file => {
                        if(!this.filesToUpload.filter(fu => fu.name === file.name).length){
                            this.filesToUpload.push(file)
                        }
                    })
                }else{
                    const message = `Sube maximo ${this.limitFile} imagenes`
                    this.$notify({title: 'Error', message, type:'error'})
                }
            })
        },
        setName(name){
            return this.$try(()=>{
                return `...${name.substring(name.length-17, name.length)}`
            })
        },
        deleteFile(index){
            this.filesToUpload.splice(index,1)
        },
        cleanFileList(){
            this.filesToUpload = []
            if (this.$refs.uploaderInput?.value) {
                this.$refs.uploaderInput.value = ''
            }
        },
    },
}
</script>

<style>

</style>