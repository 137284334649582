import { UseCase } from "~/domain/core/UseCase";
import { localizacion } from '~/services/geolocalizacion'
import { $try } from '~/services/global'

class GetCordenadasUseCase extends UseCase {
    static async execute(payload){
        const  data  =  await localizacion.getLocalizacion(payload)
        return $try(() => {
            if(data?.length) return data
        })
    }
}

export default GetCordenadasUseCase.execute