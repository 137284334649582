<template>
    <modal ref="modalEditarBodega" titulo="Editar Bodega" tamano="modal-lg" adicional="Editar" @adicional="editarBodega">
        <div class="px-5 mx-5">
            <div class="mb-3 f-14 mb-4">
                <div class="d-flex justify-content-end">
                    <div class="">
                        <p :class="{'text-green' : update.estado_bodega === 1, 'text-inactivo' : update.estado_bodega === 0}" class="text-orange text-center f-13 f-600">{{estado}}</p>
                        <el-switch
                            v-model="update.estado_bodega"
                            active-color="#41D12B"
                            inactive-color="#E14A1B"
                            :active-value="1"
                            :inactive-value="0"
                            >
                        </el-switch>
                    </div>
                </div>
                <p class="input-label-top">Nombre bodega</p>
                <el-input v-model="update.nombre" class="w-100" size="small" />
            </div>
            <titulo-divisor titulo="Ubicación" class="text-general" />
            <div class="row">
                <div class="col-6">
                    <p class="input-label-top">País</p>
                    <el-input v-model="update.pais" @keyup.native="setDireccion" size="small" />
                </div>
                <div class="col-6">
                    <p class="input-label-top">Sector / Departamento / Estado</p>
                    <el-input v-model="update.estado" @keyup.native="setDireccion" size="small" />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="input-label-top">Ciudad</p>
                    <el-input v-model="update.ciudad" @keyup.native="setDireccion" size="small" />
                </div>
                <div class="col-6">
                    <p class="input-label-top">Dirección</p>
                    <el-input v-model="update.direccion" @keyup.native="setDireccion" size="small" />
                </div>
            </div>
            <titulo-divisor titulo="Ubicación por mapa" class="text-general" />
            <div class="mt-1">
                <mapa-leaflet ref="el_mapa" v-if="renderMapa" @localizacion="getCoordenadas" class="mapa"/>
            </div>
            <titulo-divisor titulo="Imagenes Bodega" class="text-general mt-3" />
            <div class="mt-3 d-flex">
                <upload-files ref="compFiles" @files="filesToUpload" :message="`Seleccione máximo ${limitImage} imágenes`" :filesType="filesType" :limitFile="limitImage" />
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex'
import GetCordenadasUseCase from '~/domain/use_cases/mapas/getCordenadasUseCase'
import Aws from '~/services/aws'
import uploadFiles from '../components/uploadFiles.vue'
export default {
    components: { uploadFiles },
    data() {
        return {
            update: {
                'nombre': '',
                'estado_bodega': 1,
                'bodegas_imagenes': [],
                'pais': '',
                'estado': '',
                'ciudad': '',
                'direccion': '',
                'longitud': '',
                'latitud': '',
            },
            renderMapa: false,
            idEdit: -1,
            direccion: '',
            files: [],
            filesType: 'image/png, image/jpeg, image/jpg',
            limitImag: 5,
        }
    },
    computed: {
        estado() {
            return this.update.estado_bodega === 1 ? 'Activo' : 'Inactivo'
        },
        limitImage() {
            return this.update?.bodegas_imagenes ? this.limitImag - this.update.bodegas_imagenes.length : 0
        },
    },
    methods: {
        ...mapActions({
            Action_update_bodega: 'bodega/bodega/Action_update_bodega',
            Action_get_bodegas: 'bodega/bodega/Action_get_bodegas',
        }),
        toggle(bodega){
            this.clear()
            this.idEdit = bodega.id
            this.update.nombre = bodega.nombre
            this.update.estado_bodega = bodega.estado_bodega
            this.update.bodegas_imagenes = bodega.bodegas_imagenes
            this.update.pais = bodega.pais
            this.update.estado = bodega.estado
            this.update.ciudad = bodega.ciudad
            this.update.direccion = bodega.direccion
            this.update.longitud = bodega.longitud
            this.update.latitud = bodega.latitud
            this.$refs.modalEditarBodega.toggle()
            setTimeout(() =>{
                this.renderMap();
            },1000)
            setTimeout(() => {
                this.setMarket()
            },1100)
        },
        filesToUpload(files){
            this.files = files
        },
        renderMap(){
            this.renderMapa = true;
        },
        editarBodega() {
            this.$try(async ()=>{
                let imgs = []
                if (this.files.length) {
                    const promesas = this.files.map(e => Aws.uploadFile({file: e, path: 'bodegas'}))
                    imgs = await this.resolverPromise(promesas)
                }
                this.update.bodegas_imagenes = [...imgs, ...this.update.bodegas_imagenes]
                await this.Action_update_bodega({id: this.idEdit, payload: this.update})
                await this.Action_get_bodegas()
                this.$refs.modalEditarBodega.toggle()
            })
        },
        async resolverPromise(promesas){
            const promises = await Promise.allSettled(promesas)
            return promises.map(e => {
                if (e.status === 'fulfilled') return { imagen: e.value}
            })
        },
        setDireccion(){
            this.direccion = this.update.pais + " " + this.update.estado + " " + this.update.ciudad + " " + this.update.direccion
            this.pressed(this.direccion)
        },
        pressed(direccion){
            this.direccion = direccion
            let interval = setInterval(() => {
                if(direccion == this.direccion) this.geoLocalizacion()
                clearInterval(interval);
            }, 600);
        },
        async geoLocalizacion(){
            const data = await GetCordenadasUseCase(this.direccion)
            if (data) {
                this.$refs.el_mapa.setLocalizacionProp({latitud: data[0].lat, longitud: data[0].lon, zoom: this.setZoom()})
            }
        },
        setZoom(){
            if ([this.update.estado, this.update.ciudad, this.update.direccion].every(el => el === '')) return 4
            if ([this.update.ciudad, this.update.direccion].every(el => el === '')) return 6
            if ([this.update.direccion].every(el => el === '')) return 10
            return 15
        },
        getCoordenadas(coordenadas){
            this.update.latitud = coordenadas.latitud
            this.update.longitud = coordenadas.longitud
        },
        setMarket(){
            this.$refs.el_mapa.setLocalizacionProp({latitud: this.update.latitud, longitud: this.update.longitud, zoom: this.setZoom()})
        },
        clear(){
            this.update = { 'nombre': '', 'estado_bodega': 1, 'bodegas_imagenes': [], 'pais': '', 'estado': '', 'ciudad': '', 'direccion': '', 'longitud': '', 'latitud': '' },
            this.$refs.compFiles.cleanFileList()
            this.files = []
        }
    },
}
</script>

<style>
    .text-inactivo{
        color: #E14A1B;
    }
    .mapa{
        height: 250px;
    }
</style>