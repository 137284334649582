<template>
    <modal ref="modalAgregarImagen" titulo="Agregar Imagen" tamano="modal-lg" adicional="Agregar" @adicional="agregarImagen">
        <div class="px-5 mx-5">
            <p class="text-center text-general f-20 f-600 mb-4">{{ bodega.nombre }}</p>
            <upload-files ref="compoFiles" @files="filesToUpload" :message="`Seleccione máximo ${limitImage} imágenes`" :filesType="filesType" :limitFile="limitImage" />
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex'
import Aws from '~/services/aws'
import uploadFiles from '../components/uploadFiles.vue'
export default {
  components: { uploadFiles },
    name: 'AgregarImagen',
    data() {
        return {
            bodega: {},
            files: [],
            limitImag: 5,
            filesType: 'image/png, image/jpeg, image/jpg',
        }
    },
    computed: {
        limitImage() {
            return this.bodega?.bodegas_imagenes ? this.limitImag - this.bodega.bodegas_imagenes.length : 0
        }
    },
    methods: {
        ...mapActions({
            Action_update_bodega: 'bodega/bodega/Action_update_bodega',
            Action_get_bodegas: 'bodega/bodega/Action_get_bodegas',
        }),
        toggle(bodega){
            this.clear()
            this.bodega = bodega
            this.$refs.modalAgregarImagen.toggle()
        },
        filesToUpload(files){
            this.files = files
        },
        agregarImagen(){
            this.$try(async () => {
                let imgs = []
                if (this.files.length) {
                    const promesas = this.files.map(e => Aws.uploadFile({file: e, path: 'bodegas'}))
                    imgs = await this.resolverPromise(promesas)
                }
                this.bodega.bodegas_imagenes = [...imgs, ...this.bodega.bodegas_imagenes]
                await this.Action_update_bodega({id: this.bodega.id, payload: this.bodega})
                this.$refs.modalAgregarImagen.toggle()
                await this.Action_get_bodegas()
            });
        },
        async resolverPromise(promesas){
            const promises = await Promise.allSettled(promesas)
            return promises.map(e => {
                if (e.status === 'fulfilled') return { imagen: e.value}
            })
        },
        clear(){
            this.bodega = {}
            this.$refs.compoFiles.cleanFileList()
            this.files = []
        }
    },
}
</script>

<style lang="scss" scoped>
</style>